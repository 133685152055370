import React, { useEffect, useState } from "react";
import "./Header.css";
import { useLocation } from "react-router-dom";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import Logo from "../Assets/images/Final Logo.png";
import { HashLink as Link } from "react-router-hash-link";
import { IoCloseOutline } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [serviceMenuShow, setServiceMenuShow] = useState(false);
  const location = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  useEffect(() => {
    const dropdown = document.getElementById("services-dropdown");

    if (dropdown) {
      if (location.pathname === "/") {
        if (isScrolled) {
          dropdown.classList.add("color-black");
          dropdown.classList.remove("color-white");
        } else {
          dropdown.classList.add("color-white");
          dropdown.classList.remove("color-black");
        }
      } else {
        dropdown.classList.add("color-black");
        dropdown.classList.remove("color-white");
      }
    }
  }, [isScrolled, location]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isHomePage = location.pathname === "/";
  const headerBackground = isScrolled ? "bg-white" : "bg-none";
  const customHeader = isHomePage ? headerBackground : "bg-white";

  const divClassName =
    location.pathname === "/" ? (isScrolled ? "d-none" : "d-flex") : "d-none";

  const getHomePageClassName = (isScrolled) => {
    return isScrolled ? "text-black" : "text-white";
  };
  const defaultClassName = "text-black";
  const homePageClassName = getHomePageClassName(isScrolled);
  const textClassName =
    location.pathname === "/" ? homePageClassName : defaultClassName;
  const handleToggleSidebar = () => {
    setShowMenu((prevState) => !prevState);
  };
  if (showMenu === true) {
    document.body.classList.add("stop-scrolling");
  } else {
    document.body.classList.remove("stop-scrolling");
  }

  return (
    <header
      className={`navbar navbar-light navbar-expand-md navigation-clean-search header_style ${customHeader} `}
      style={{
        background: isScrolled ? "white" : isHomePage ? "transparent" : "white",
        position: isHomePage || isScrolled ? "fixed" : "static",
        top: isHomePage || isScrolled ? "0" : "unset",
        width: "100%",
        transition: "background 0.3s, position 0.3s",
        zIndex: "1000",
      }}
    >
      <div>
        <div className="header-container">
          <div className="header_logo_width">
            <div className="img_box">
              <Link to={"/"}>
                <img src={Logo} alt="Company Logo" className="logo_style" />
              </Link>
            </div>
          </div>
          <button
            onClick={handleToggleSidebar}
            className={`navbar-toggler ${textClassName}`}
            data-target="#navcol-1"
          >
            <RxHamburgerMenu size="20px" />
          </button>
          <div
            className={`collapse navbar-collapse justify-content-end ${
              isScrolled ? "text-black" : "text-white"
            }`}
            id="navcol-1"
          >
            <div className="header-text">
              <div
                className={`${divClassName} gap-20 mb-4 justify-content-center ml-10`}
              >
                <div
                  style={{ marginLeft: "30%" }}
                  className="d-flex align-items-center gap-10"
                >
                  <FaPhoneAlt color="#0d6efd" />
                  <a
                    className={`link_style ${textClassName}`}
                    href="tel:+97145841556"
                  >
                    +971 54 54 03 03 6
                  </a>
                </div>
                <div className="d-flex align-items-center gap-10">
                  <FaEnvelope color="#0d6efd" />
                  <a
                    className={`link_style ${textClassName}`}
                    href="mailto:sales@daynafreight.com"
                  >
                    info@emarsaleglobalshipping.com
                  </a>{" "}
                </div>
              </div>
              <div className="d-flex gap-20 align-items-center">
                <Link to={"/"} className={`link_style ${textClassName}`}>
                  Home
                </Link>
                <Link to={"/About"} className={`link_style ${textClassName}`}>
                  About Us
                </Link>
                <li className="list-style-none">
                  <Link
                    to="/services"
                    className={`link_style ${textClassName}`}
                  >
                    Services
                  </Link>
                  <ul className="dropdown-content list-style-none">
                    <li>
                      <Link to="/services#oceanfreight">
                        Sea Freight Forwarding
                      </Link>
                    </li>
                    <li>
                      <Link to="/services#airfreight">
                        {" "}
                        Air Freight Forwarding
                      </Link>
                    </li>
                    <li>
                      <Link to="/services#landfreight">
                        Land Freight Forwarding
                      </Link>
                    </li>
                    <li>
                      <Link to="/services#warehousing">Warehousing</Link>
                    </li>

                    <li>
                      <Link to="/services#CustomsClearance">
                        {" "}
                        Customs Clarens
                      </Link>
                    </li>
                    <li>
                      <Link to="/services#oil">
                        Oil and Gas Import & Export
                      </Link>
                    </li>
                    <li>
                      <Link to="/services#SpareParts">
                        {" "}
                        AutoParts & Manufacturing Exports
                      </Link>
                    </li>
                    <li>
                      <Link to="/services#luxury">
                        Luxury Car Import and Export
                      </Link>
                    </li>
                    <li>
                      <Link to="/services#cargo">Project Cargo Handling</Link>
                    </li>
                  </ul>
                </li>
                <Link to={"/gallery"} className={`link_style ${textClassName}`}>
                  Gallery
                </Link>
                <Link to={"/clients"} className={`link_style ${textClassName}`}>
                  Clients
                </Link>
                <Link to={"/career"} className={`link_style ${textClassName}`}>
                  Careers
                </Link>

                <Link to={"/contact"} className={`link_style ${textClassName}`}>
                  Contact Us
                </Link>
                <div className="button-container">
                  <Link to={"/online"} className={`quoteButton`}>
                    Request A Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            id="sidebar"
            className={`sidebar_menu ${showMenu ? "show" : ""}`}
          >
            <button
              onClick={handleToggleSidebar}
              className="close-icon"
              data-target="#navcol-1"
            >
              <IoCloseOutline size="20px" />
            </button>
            <div className="d-flex mt-5 flex-column text-center gap-10">
              <img src={Logo} alt="Company Logo" className="w-50 m-auto" />
              <div className="d-flex flex-column gap-10">
                <Link to={"/"} className="menu_button_style">
                  Home
                </Link>
                <Link to={"/About"} className="menu_button_style">
                  About Us
                </Link>
                <li className="list-style-none">
                  <span
                    className="menu_button_style"
                    onClick={() => setServiceMenuShow(!serviceMenuShow)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    Services
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{
                        marginLeft: "8px",
                        transition: "transform 0.3s",
                      }}
                      className={serviceMenuShow ? "rotate-icon" : ""}
                    />
                  </span>

                  <ul
                    className={`${
                      serviceMenuShow ? "d-block" : "d-none"
                    } list-style-none menu_service_style`}
                    id="menuServices"
                  >
                    <li className=" mb-3">
                      <Link to="/services#oceanfreight">
                        Ocean Freight Forwarding
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/services#airfreight">
                        Air Freight Forwarding
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/services#landfreight">
                        Land Freight Forwarding
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/services#warehousing">Warehousing</Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/services#CustomsClearance">
                        Customs Clarens
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/services#oil">
                        Oil and Gas Import & Export
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/services#SpareParts">
                        AutoParts & Manufacturing Exports
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/services#luxury">
                        Luxury Car Import and Export
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/services#cargo">Project Cargo Handling</Link>
                    </li>
                  </ul>
                </li>
                <Link to={"/gallery"} className="menu_button_style">
                  Gallery
                </Link>
                <Link to={"/clients"} className="menu_button_style">
                  Clients
                </Link>
                <Link to={"/career"} className="menu_button_style">
                  Careers
                </Link>
                <Link to={"/online"} className="menu_button_style">
                  Request A Quote
                </Link>
                <Link to={"/contact"} className="menu_button_style">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
