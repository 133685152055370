import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import aboutimg3 from "../Assets/images/About/Profile Big Ship.jpg";

const Profile = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div className="container my-5">
      {/* Profile Section */}
      <div className="container">
        <div className="row align-items-center bg-light p-4 rounded shadow mb-5">
          <div
            className="col-lg-6 d-flex align-items-center"
            data-aos="flip-left"
          >
            <div>
              <h2 className="text-primary mb-4">PROFILE</h2>
              <p className="text-muted" style={{ textAlign: "justify" }}>
                It is a pleasure to introduce our company Emarsale Global
                Shipping L.L.C, an international freight forwarding company
                based in Dubai, UAE. The company provides international shipping
                and logistics solutions to clients globally. Emarsale is managed
                by a group of highly experienced professionals with decades of
                experience in the shipping and freight forwarding industry. The
                company is backed up by strong financial prospects in business
                expansions, trade development, and information technology. Our
                corporate vision is to become a preferred integrated global
                shipping company, with a customer oriented approach and an
                employee-friendly environment. The company has a very strong
                global network with partners in North America, South America,
                Europe, USA, Far East, North East Asia, South East Asia, CIS,
                East Africa, West Africa, and the Middle East. The company has a
                strong brand name and is well known to its customers for a
                reliable range of international freight forwarding and logistics
                services in a more cost-effective manner.
              </p>
            </div>
          </div>
          <div className="col-lg-6 text-center" data-aos="zoom-out-up">
            <img
              src={aboutimg3}
              alt="Container Ship - Emarsale Shipping"
              className="img-fluid rounded shadow-sm"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
        <div className="row align-items-center bg-light p-4 rounded shadow mb-5">
          <div
            // className="col-12"
            // style={{
            //   // boxShadow: "0 0px 8px rgba(0, 0, 0, 0.4)",
            //   padding: "20px",
            //   borderRadius: "10px",
            // }}
          >
            <h4 className="font-weight-bold text-center">Our Services</h4>
            <p className="text-muted" style={{ textAlign: "justify" }}>
              Freight Forwarding - Exports-Airfreight and Sea freight - Import
              - Airfreight and Sea Freight - Cross Trade - Air Freight and Sea
              Freight - Customs clearance - Transportation (Within UAE and GCC)
              Warehousing and 3PL - Storage and logistics solutions - Supply
              chain and distribution management - Inventory management - Cross
              stuffing services - Transfer of ownership - Customs clearance and
              transportation Projects Logistics and Heavy Lifts - Chartering -
              Break bulk and Ro-Ro shipments - Barge and tug shipments - Heavy
              haul land transport - We handle shipments professionally from door
              to door and end to end basis. We have proven ourselves to be the
              best in the field starting with customer service and handling
              important packages including Oil & Gas, Auto Spare Parts, Luxury
              cars, Machineries, Apparels, Medical products & equipment,
              Chemicals, Electronics, Telecommunication products, and also food
              items and all General Products. Most importantly, our ability to
              meet our clients’ requirements in terms of service and delivery
              time.
            </p>
          </div>
        </div>
      </div>

      {/* Vision Section */}
      <section
        className="row align-items-center bg-light p-4 rounded shadow mb-5"
        id="vision"
      >
        {/* Text on large screens, image on small screens */}
        <div className="col-lg-6 order-lg-1" data-aos="fade-up">
          <h2 className="text-primary mb-4">VISION</h2>
          <p className="text-muted" style={{ textAlign: "justify" }}>
            At Emarsale Global Shipping L.L.C, we envision a future where
            logistics is no longer a challenge but a streamlined process. We
            strive to be your trusted partner in navigating the global supply
            chain, offering innovative solutions that keep your business moving
            forward.
          </p>
        </div>
        <div className="col-lg-6 order-lg-2 text-center" data-aos="fade-up">
          <img
            src="https://www.yusen-logistics.com/in_en/sites/india/files/2022-04/vision_mission_value_0.png"
            alt="Vision - Global Logistics"
            className="img-fluid rounded shadow-sm"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </section>

      {/* Mission Section */}
      <section
        className="row align-items-center bg-light p-4 rounded shadow mb-5"
        id="mission"
      >
        {/* Text on large screens, image on small screens */}
        <div className="col-lg-6 order-lg-1" data-aos="zoom-in">
          <h2 className="text-primary mb-4">MISSION</h2>
          <p className="text-muted" style={{ textAlign: "justify" }}>
            Our mission is to simplify the complexities of global shipping by
            offering customized freight solutions that cater to the unique needs
            of each client. We are committed to delivering exceptional service,
            ensuring the safe and efficient movement of your goods, whether by
            sea, air, or land.
          </p>
        </div>
        <div className="col-lg-6 order-lg-2 text-center" data-aos="fade-up">
          <img
            src="https://sphereonelogistics.com/wp-content/uploads/2021/04/about-us-8-1-e1567167618781-1024x634.jpg"
            alt="Mission - Global Logistics"
            className="img-fluid rounded shadow-sm"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </section>
    </div>
  );
};

export default Profile;
