import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "animate.css";
import image1 from "../Assets/images/BannerImage/01 AIR FREIGHT.jpg";
import image2 from "../Assets/images/BannerImage/02 OCEAN FREIGHT.jpg";
import image3 from "../Assets/images/BannerImage/03 LAND FREIGHT – 1.jpg";
import image4 from "../Assets/images/BannerImage/04 WAREHOUSING.jpg";
import image5 from "../Assets/images/BannerImage/05 Oil and Gas Importing & Exporting.jpg";

const ImageGallery = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    // Trigger re-render of the animation by updating the key
    setAnimationKey((prevKey) => prevKey + 1);
  }, [activeIndex]);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={activeIndex}
      onSelect={handleSelect}
      interval={2000}
      pause={false}
      wrap={true}
      controls={false}
      fade
    >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image2}
          alt="Sea Freight"
          style={{ objectFit: "cover", height: "100vh" }}
        />
        <Carousel.Caption
          key={animationKey}
          className="d-flex flex-column justify-content-center align-items-center animate__animated animate__fadeInLeft"
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: "absolute",
            color: "white",
            textAlign: "center",
            animationDuration: "900ms",
          }}
        >
          <h1 style={{ fontSize: "clamp(2rem, 5vw, 6rem)" }}>
            SEA FREIGHT <br /> FORWARDING
          </h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image1}
          alt="Air Freight"
          style={{ objectFit: "cover", height: "100vh" }}
        />
        <Carousel.Caption
          key={animationKey}
          className="d-flex flex-column justify-content-center align-items-center animate__animated animate__fadeInLeft"
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: "absolute",
            color: "white",
            textAlign: "center",
            animationDuration: "900ms",
          }}
        >
          <h1 style={{ fontSize: "clamp(2rem, 5vw, 6rem)" }}>
            AIR FREIGHT <br /> FORWARDING
          </h1>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image3}
          alt="Land Freight"
          style={{ objectFit: "cover", height: "100vh" }}
        />
        <Carousel.Caption
          key={animationKey}
          className="d-flex flex-column justify-content-center align-items-center animate__animated animate__fadeInLeft"
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: "absolute",
            color: "white",
            textAlign: "center",
            animationDuration: "900ms",
          }}
        >
          <h1 style={{ fontSize: "clamp(2rem, 5vw, 5rem)" }}>
            LAND FREIGHT <br /> FORWARDING
          </h1>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image4}
          alt="Warehousing"
          style={{ objectFit: "cover", height: "100vh" }}
        />
        <Carousel.Caption
          key={animationKey}
          className="d-flex flex-column justify-content-center align-items-center animate__animated animate__fadeInLeft"
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: "absolute",
            color: "white",
            textAlign: "center",
            animationDuration: "900ms",
          }}
        >
          <h1 style={{ fontSize: "clamp(2rem, 5vw, 5rem)" }}>
            WAREHOUSING <br /> FORWARDING
          </h1>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image5}
          alt="Oil and Gas"
          style={{ objectFit: "cover", height: "100vh" }}
        />
        <Carousel.Caption
          key={animationKey}
          className="d-flex flex-column justify-content-center align-items-center animate__animated animate__fadeInLeft"
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: "absolute",
            color: "white",
            textAlign: "center",
            animationDuration: "900ms",
          }}
        >
          <h1 style={{ fontSize: "clamp(2rem, 5vw, 5rem)" }}>
            OIL AND GAS IMPORTING <br /> & EXPORTING
          </h1>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default ImageGallery;
