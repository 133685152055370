import React, { useEffect } from "react";
import { motion } from "framer-motion";
import ImageGallery from "../components/ImageGallery";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import welcomeimg from "../Assets/images/WELCOME TO Emarsale Shipping LLC.jpg";
import service1 from "../Assets/images/serviceimg/AdobeStock_118972899_WM-1084x724.png";
import service2 from "../Assets/images/serviceimg/Group 502.jpg";
import service3 from "../Assets/images/serviceimg/Group 504.jpg";
import service4 from "../Assets/images/serviceimg/Group 505@2x.jpg";
import service5 from "../Assets/images/serviceimg/Group 11.jpg";
import service6 from "../Assets/images/serviceimg/Group 12.jpg";
import service7 from "../Assets/images/serviceimg/Group 13.jpg";
import service8 from "../Assets/images/serviceimg/Group 14.jpg";
import homeing from "../Assets/images/Image 32.png";
import roundimg1 from "../Assets/images/roundimg/05 Safe & Fast delivery.jpg";
import roundimg2 from "../Assets/images/roundimg/04 Supply Chain Solutions.jpg";
import roundimg3 from "../Assets/images/roundimg/24x7.jpg";
import roundimg4 from "../Assets/images/roundimg/01 Worldwide Network.jpg";
import roundimg5 from "../Assets/images/roundimg/Group 15.png";
import roundimg6 from "../Assets/images/roundimg/Customer Clearance.jpg";
import testmodal1 from "../Assets/images/Testmodals-img/01 IMG.png";
import testmodal2 from "../Assets/images/Testmodals-img/02 IMG.png";
import testmodal3 from "../Assets/images/Testmodals-img/03 IMG.png";
import testmodal4 from "../Assets/images/Testmodals-img/04  IMG.png";
import testmodal5 from "../Assets/images/Testmodals-img/05  IMG.png";
import testmodal6 from "../Assets/images/Testmodals-img/06  IMG.png";
import { HashLink as Link } from "react-router-hash-link";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "animate.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
      mirror: false,
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    pauseOnHover: true,
  };

  const testimonials = [
    {
      name: "kumaresan kumar",
      role: "RMS Enterprises",
      image: testmodal1,
      text: "Emarsale Global shipping is one of the most trust-able and reliable logistics service providers in Dubai. I am doing all kind of import export and clearing services with emarsale shipping way for long and i am very happy with their services like on time delivery, affordable price and free to contact any time.. So i decided to do my all business with them... Thank you emarsale",
      rating: 5,
    },
    {
      name: "Arunagiri Rathod",
      role: "Star Gate Impex",
      image: testmodal2,
      text: `The best Shipping & Freight Forwarding Company in Dubai and I'd like to say the best in the UAE. They always go above and beyond to help and are always on time with their promises. They are polite, resourceful and available at any time of the day, trying their best to keep your businesses running. A special thanks to Emarsale team.`,
      rating: 4,
    },
    {
      name: "kishore krish",
      role: "Kalai Engineering",
      image: testmodal3,
      text: "It was a wonderful experience with Emarsale global shipping Services...   Providing excellent service for the customs clearance,import and export..  Especially company have  professional Experts.",
      rating: 5,
    },
    {
      name: "Abraham Mathew",
      role: "Century Polypack",
      image: testmodal4,
      text: "One of the best freight forwarding and clearing agent in GCC. Great quality of service with dedication and very much reliable.",
      rating: 4,
    },
    {
      name: "Sadia Arshad",
      role: "Kfs Auto Bearing",
      image: testmodal5,
      text: `Recently we have done our shipment through Emarsale . There communication and interaction is up to the mark, very good service,i have contacted with mr Aslam throughout the event and he’s been extremely supportive and friendly,and we hope to do more work in future .
            Best of luck to the entire team`,
      rating: 5,
    },
    {
      name: `Imran Syed`,
      role: "Supreme Equipments",
      image: testmodal6,
      text: `Emarsale Shipping has handling our shipments since 1+ years, and their service really excellent. there staff gives exceptional support with good service on a case-by-case basis.`,
      rating: 4,
    },
  ];

  return (
    <div
      style={{
        backgroundImage: "url('https://example.com/your-background-image.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <ImageGallery />
      {/* Welcome Section */}
      <div className="container my-5">
        <div className="row align-items-center">
          <div className="col-md-6" data-aos="fade-right">
            <h2>
              <span style={{ color: "#0d6efd" }}>WELCOME TO</span>
              <br />
              Emarsale Global Shipping L.L.C
            </h2>
            <p style={{ textAlign: "justify", lineHeight: "2.0" }}>
              Emarsale-Shipping L.L.C is one of the reputed freight-forwarding
              and logistics companies in the region, supporting trade and
              industry with global movements of goods through supply chain
              management, warehousing, contract logistics, worldwide ocean, air
              freight, and land transportation. We connect businesses with
              global trade, offering services, solutions, and innovative ideas
              for complex needs of varied business entities across the globe,
              meeting their logistics challenges.
            </p>
          </div>
          <div className="col-md-6 text-center mt-4" data-aos="fade-up">
            {" "}
            {/* Add mt-4 for margin-top */}
            <img
              src={welcomeimg}
              alt="Container Ship"
              className="img-fluid"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>

      {/* Services Section */}
      <section id="services" className="services section py-5 bg-light">
        {/* Section Title */}
        <div className="container text-center mb-4">
          <h2 className="display-4 mb-3" data-aos="fade-up">
            Our Services
          </h2>
        </div>
        {/* End Section Title */}

        <div className="container">
          <div className="row gy-4">
            {/* Card 1 */}
            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <Link to="/services#oceanfreight">
                <div className="position-relative">
                  <motion.div
                    className="card border-0 shadow rounded position-relative"
                    whileHover={{ scale: 1.05, opacity: 0.8 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={service1}
                      alt="Service 1"
                      className="card-img-top rounded"
                      style={{ height: "500px", objectFit: "cover" }}
                    />
                  </motion.div>
                  <div className="position-absolute bottom-0 start-0 p-3 text-white">
                    <h5 style={{ fontWeight: "bold" }}>
                      SEA FREIGHT FORWARDING{" "}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            {/* Card 2 */}
            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <Link to="/services#airfreight">
                <div className="position-relative">
                  <motion.div
                    className="card border-0 shadow rounded position-relative"
                    whileHover={{ scale: 1.05, opacity: 0.8 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={service2}
                      alt="Service 2"
                      className="card-img-top rounded"
                      style={{ height: "500px", objectFit: "cover" }}
                    />
                  </motion.div>
                  <div className="position-absolute bottom-0 start-0 p-3 text-white">
                    <h5 style={{ fontWeight: "bold" }}>
                      AIR FREIGHT <br /> FORWARDING
                    </h5>
                  </div>
                </div>
              </Link>
            </div>

            {/* Card 3 */}
            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <Link to="/services#landfreight">
                <div className="position-relative">
                  <motion.div
                    className="card border-0 shadow rounded position-relative"
                    whileHover={{ scale: 1.05, opacity: 0.8 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={service3}
                      alt="Service 3"
                      className="card-img-top rounded"
                      style={{ height: "500px", objectFit: "cover" }} // Adjust height here
                    />
                  </motion.div>
                  <div className="position-absolute bottom-0 start-0 p-3 text-white">
                    <h5 style={{ fontWeight: "bold" }}>
                      LAND FREIGHT <br /> FORWARDING
                    </h5>
                  </div>
                </div>
              </Link>
            </div>

            {/* Card 4 */}
            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {" "}
              <Link to="/services#warehousing">
                <div className="position-relative">
                  <motion.div
                    className="card border-0 shadow rounded position-relative"
                    whileHover={{ scale: 1.05, opacity: 0.8 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={service4}
                      alt="Service 4"
                      className="card-img-top rounded"
                      style={{ height: "500px", objectFit: "cover" }} // Adjust height here
                    />
                  </motion.div>
                  <div className="position-absolute bottom-0 start-0 p-3 text-white">
                    <h5 style={{ fontWeight: "bold" }}>
                      WAREHOUSING & DISTRIBUTION
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            {/* Card 5 */}
            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {" "}
              <Link to="/services#oil">
                <div className="position-relative">
                  <motion.div
                    className="card border-0 shadow rounded position-relative"
                    whileHover={{ scale: 1.05, opacity: 0.8 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={service5}
                      alt="Service 4"
                      className="card-img-top rounded"
                      style={{ height: "500px", objectFit: "cover" }} // Adjust height here
                    />
                  </motion.div>
                  <div className="position-absolute bottom-0 start-0 p-3 text-white">
                    <h5 style={{ fontWeight: "bold" }}>
                      OIL & GAS <br />
                      EXPORT & IMPORT
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            {/* Card 6 */}
            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {" "}
              <Link to="/services#cargo">
                <div className="position-relative">
                  <motion.div
                    className="card border-0 shadow rounded position-relative"
                    whileHover={{ scale: 1.05, opacity: 0.8 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={service6}
                      alt="Service 4"
                      className="card-img-top rounded"
                      style={{ height: "500px", objectFit: "cover" }} // Adjust height here
                    />
                  </motion.div>
                  <div className="position-absolute bottom-0 start-0 p-3 text-white">
                    <h5 style={{ fontWeight: "bold" }}>
                      PROJECT CARGO <br />
                      HANDLING
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            {/* Card 7 */}
            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {" "}
              <Link to="/services#luxury">
                <div className="position-relative">
                  <motion.div
                    className="card border-0 shadow rounded position-relative"
                    whileHover={{ scale: 1.05, opacity: 0.8 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={service7}
                      alt="Service 4"
                      className="card-img-top rounded"
                      style={{ height: "500px", objectFit: "cover" }} // Adjust height here
                    />
                  </motion.div>
                  <div className="position-absolute bottom-0 start-0 p-3 text-white">
                    <h5 style={{ fontWeight: "bold" }}>
                      LUXURY CAR <br /> IMPORT & EXPORT
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            {/* Card 8 */}
            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {" "}
              <Link to="/services#SpareParts">
                <div className="position-relative">
                  <motion.div
                    className="card border-0 shadow rounded position-relative"
                    whileHover={{ scale: 1.05, opacity: 0.8 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={service8}
                      alt="Service 4"
                      className="card-img-top rounded"
                      style={{ height: "500px", objectFit: "cover" }} // Adjust height here
                    />
                  </motion.div>
                  <div className="position-absolute bottom-0 start-0 p-3 text-white">
                    <h5 style={{ fontWeight: "bold" }}>
                      AUTO PARTS & MANUFACTURING PRODUCTS
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* new section */}

      <Container className="text-center py-5">
        <Row className="justify-content-center">
          <Col
            xs={6}
            md={4}
            className="d-flex flex-column align-items-center mb-4"
          >
            <Image
              src={roundimg1}
              roundedCircle
              className="mb-3 animate__animated animate__bounce animate__infinite  image-responsive"
              style={{ width: "150px", height: "150px" }}
            />
            <h4 className="font-weight-bold text-dark">SAFE & FAST DELIVERY</h4>
          </Col>
          <Col
            xs={6}
            md={4}
            className="d-flex flex-column align-items-center mb-4"
          >
            <Image
              src={roundimg2}
              roundedCircle
              className="mb-3 animate__animated animate__bounce animate__infinite"
              style={{ width: "150px", height: "150px" }}
            />
            <h4 className="font-weight-bold text-dark">
              SUPPLY CHAIN SOLUTIONS
            </h4>
          </Col>
          <Col
            xs={6}
            md={4}
            className="d-flex flex-column align-items-center mb-4"
          >
            <Image
              src={roundimg3}
              roundedCircle
              className="mb-3 animate__animated animate__bounce animate__infinite"
              style={{ width: "150px", height: "150px" }}
            />
            <h4 className="font-weight-bold text-dark">24/7 SUPPORT</h4>
          </Col>
          <Col
            xs={6}
            md={4}
            className="d-flex flex-column align-items-center mb-4"
          >
            <Image
              src={roundimg4}
              roundedCircle
              className="mb-3 animate__animated animate__bounce animate__infinite"
              style={{ width: "150px", height: "150px" }}
            />
            <h4 className="font-weight-bold text-dark">WORLDWIDE NETWORK</h4>
          </Col>
          <Col
            xs={6}
            md={4}
            className="d-flex flex-column align-items-center mb-4"
          >
            <Image
              src={roundimg5}
              roundedCircle
              className="mb-3 animate__animated animate__bounce animate__infinite"
              style={{ width: "150px", height: "150px" }}
            />
            <h4 className="font-weight-bold text-dark">TRACKING SUPPORT</h4>
          </Col>
          <Col
            xs={6}
            md={4}
            className="d-flex flex-column align-items-center mb-4"
          >
            <Image
              src={roundimg6}
              roundedCircle
              className="mb-3 animate__animated animate__bounce animate__infinite"
              style={{ width: "150px", height: "150px" }}
            />
            <h4 className="font-weight-bold text-dark">CUSTOMS CLEARANCE</h4>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        className=" border-5 w-75 shadow  mb-5 bg-white rounded "
      >
        <Row className="align-items-center  ">
          <Col
            md={7}
            className="text-center text-md-start bg-primary text-white p-4"
          >
            <h4 className="fw-normal mb-3">
              We Provide Service Across The Globe
            </h4>
            <p className="fs-6">
              We offer a Global Logistics Network with our worldwide offices and
              high-quality distribution facilities staffed by dedicated teams of
              experts. <br />
              We have more than 5 years of experience in this field.
            </p>
          </Col>

          <Col md={5} className="text-center mt-3 mt-md-0 p-0">
            <Image
              src={homeing}
              className="img-fluid"
              style={{ maxWidth: "300px", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>

      <div
        style={{
          display: "center",
        }}
      >
        <section className="py-5 bg-light">
          <Container>
            <h2 className="text-center mb-4">What Our Customers Say</h2>
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <div key={index} className="testimonial">
                  <Row className="align-items-center">
                    <Col
                      xs={12}
                      md={3}
                      className="d-flex d-md-block justify-content-center mb-3 mb-md-0"
                    >
                      <Image
                        src={testimonial.image}
                        roundedCircle
                        alt={testimonial.name}
                        className="img-fluid"
                        style={{ maxWidth: "200px", height: "auto" }}
                      />
                    </Col>

                    <Col xs={12} md={9}>
                      <p className="">"{testimonial.text}"</p>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="mb-0 text-sm-start">
                          {testimonial.name}
                        </h5>
                        <div className="d-flex">
                          {Array.from({ length: 5 }, (_, i) => (
                            <i
                              key={i}
                              className={`fas fa-star ${
                                i < testimonial.rating
                                  ? "text-warning"
                                  : "text-muted"
                              }`}
                              style={{ marginRight: "4px" }}
                            ></i>
                          ))}
                        </div>
                      </div>
                      <p className="small text-muted mb-0">
                        {testimonial.role}
                      </p>
                    </Col>
                  </Row>
                </div>
              ))}
            </Slider>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default Home;
