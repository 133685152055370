import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { sendEmarsaleEmail } from "../../src/api/allapi"; // Your API call function

const JobApplication = () => {
  const location = useLocation();
  const { job } = location.state || {};

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    resume: null,
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  if (!job) {
    return <div>No job selected</div>;
  }

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle file change for resume
  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      resume: e.target.files[0],
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    // Prepare form data for API submission
    const data = {
      fullname: formData.fullname,
      email: formData.email,
      message: formData.message,
    };

    try {
      await sendEmarsaleEmail(data, formData.resume); // Call the API
      setSuccessMessage("Application submitted successfully!");
      setFormData({
        fullname: "",
        email: "",
        resume: null,
        message: "",
      });
    } catch (error) {
      setErrorMessage("Failed to submit application. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="py-1 mt-1">
      <Row className="justify-content-center">
        {/* Left side: Job details */}
        <Col md={6} className="mb-4">
          <Card className="shadow-sm border-0 rounded">
            <Card.Body>
              <Card.Title as="h3" className="text-primary">
                {job.title}
              </Card.Title>
              <Card.Text   style={{ textAlign: "justify" }}>
                <strong>Description:</strong> {job.description}
              </Card.Text>
              <Card.Text>
                <strong>Qualifications:</strong> {job.qualifications}
              </Card.Text>
              <Card.Text  style={{ textAlign: "justify" }}>
                <strong>Experience:</strong> {job.Experiences}
              </Card.Text>
              <Card.Text>
                <strong>Application Date:</strong> {job.applyDate}
              </Card.Text>
              <Card.Text>
                <strong>Job Location:</strong> {job.Place}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Right side: Application form */}
        <Col md={6}>
          <Card className="shadow-sm border-0 rounded">
            <Card.Body>
              <Card.Title as="h4" className="text-primary">
                Apply for this position
              </Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="fullname"
                    value={formData.fullname}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail" className="mt-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formResume" className="mt-3">
                  <Form.Label>Resume</Form.Label>
                  <Form.Control
                    type="file"
                    name="resume"
                    onChange={handleFileChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formMessage" className="mt-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Write a message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit Application"}
                </Button>

                {successMessage && (
                  <Alert variant="success" className="mt-3">
                    {successMessage}
                  </Alert>
                )}
                {errorMessage && (
                  <Alert variant="danger" className="mt-3">
                    {errorMessage}
                  </Alert>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default JobApplication;
