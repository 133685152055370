import { Link, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Profile from "./pages/Profile";
import About from "./pages/About";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ContactForm from "./pages/ContactForm";
import Header from "./components/Header";
import Footer from "./components/Footer";
import OnlineInqury from "./pages/OnlineInqury";
import Clients from "./pages/Clients";
import Careers from "./pages/Careers";
import JobApplication from "./components/JobApplication";
import whatsappicon from "../src/Assets/images/whatsapp.svg";
import { Image } from "react-bootstrap";
import Gallery from "./pages/Gallery";
import Loader from "./components/loader/loader";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      window.scrollTo(0, 0);
    }
  }, [location, isLoading]);

  const bounceAnimation = {
    animation: "bounce 2s infinite",
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 1050,
  };

  const bounceKeyframes = `
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-15px);
      }
      60% {
        transform: translateY(-7px);
      }
    }
  `;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/About" element={<About />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/online" element={<OnlineInqury />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/career" element={<Careers />} />
        <Route path="/apply/:id" element={<JobApplication />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
      <>
        <style>{bounceKeyframes}</style>
        <div style={bounceAnimation}>
          <Link to="https://api.whatsapp.com/send/?phone=%2B971545403036&text&type=phone_number&app_absent=0">
            <Image
              src={whatsappicon}
              alt="WhatsApp Icon"
              style={{ width: "60px", height: "60px" }}
              fluid
            />
          </Link>
        </div>
      </>
      <Footer />
    </>
  );
}

export default App;
