import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Container, Row, Col } from "react-bootstrap";
import google from "../Assets/images/Group 146.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      style={{
        background: "linear-gradient(to bottom, #003C6E, #000032)",
        color: "white",
      }}
      className="text-white py-5"
    >
      <Container>
        <Row>
          <Col md={4} className="mb-0">
            <h5 className="text-warning mb-3">Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  to={"/"}
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to={"/About"}
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to={"/clients"}
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Clients
                </Link>
              </li>
              <li>
                <Link
                  to={"/contact"}
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to={"/career"}
                  className="text-white d-block mb-2 text-decoration-none "
                >
                  Careers
                </Link>
              </li>
              <br />
              <div className="social-icons mt-3 d-none d-md-block">
                <div>Follow On Us</div>
                <Link to={"https://facebook.com"} className="me-3">
                  <i
                    className="bi bi-facebook text-white"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </Link>
                <Link to={"https://twitter.com"} className="me-3">
                  <i
                    className="bi bi-twitter-x text-white"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </Link>
                <Link to={"https://linkedin.com"} className="me-3">
                  <i
                    className="bi bi-linkedin text-white"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </Link>
                <Link to={"https://instagram.com"} className="me-3">
                  <i
                    className="bi bi-instagram text-white"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </Link>
              </div>
            </ul>
          </Col>
          <Col md={4} className="mb-4">
            <h5 className="text-warning mb-3">Logistic Services</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  to="/services#airfreight"
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Air Freight Forwarding
                </Link>
              </li>
              <li>
                <Link
                  to="/services#oceanfreight"
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Sea Freight Forwarding
                </Link>
              </li>
              <li>
                <Link
                  to="/services#landfreight"
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Land Freight Forwarding
                </Link>
              </li>
              <li>
                <Link
                  to="/services#warehousing"
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Warehousing Facilities
                </Link>
              </li>
              {/* addmore */}
              <li>
                <Link
                  to="/services#warehousing"
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Customs Clarens
                </Link>
              </li>
              <li>
                <Link
                  to="/services#warehousing"
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Project Cargo handling
                </Link>
              </li>
              <li>
                <Link
                  to="/services#warehousing"
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Luxury Car Import and Export
                </Link>
              </li>
              <li>
                <Link
                  to="/services#warehousing"
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  AutoParts & Manufacturing Exports
                </Link>
              </li>
              {/* End */}
              <li>
                <Link
                  to="/services#oceanfreight"
                  className="text-white d-block mb-2 text-decoration-none"
                >
                  Oil and Gas Import & Export
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={4} className="mb-4">
            <h5 className="text-warning mb-3">Contact Us</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <i className="bi bi-envelope-fill me-2"></i>
                <a
                  href="mailto:sales@daynafreight.com"
                  className="text-white text-decoration-none"
                >
                  info@emarsaleglobalshipping.com
                </a>
              </li>

              <li className="mb-2">
                <i className="bi bi-telephone-fill me-2"></i>
                <a
                  href="tel:+97145841556"
                  className="text-white text-decoration-none"
                >
                  +971 54 54 03 03 6
                </a>
              </li>
              <li className="mb-2">
                <i className="bi bi-telephone-fill me-2"></i>
                <a
                  href="tel:+97145841556"
                  className="text-white text-decoration-none"
                >
                  +971 50 87 00 87 2
                </a>
              </li>
              <li className="text-white mb-2">
                <i className="bi bi-clock-fill me-2"></i> Mon – Fri : 08 AM to
                05:30 PM
              </li>
              <li className="text-white mb-2">
                <i className="bi bi-calendar-x-fill me-2"></i>Sat & Sun: Closed
              </li>
              <br />
              <Link to="https://www.google.co.in/">
                <li className="mt-3">
                  <img
                    src={google}
                    alt="Google Rating"
                    className="img-fluid"
                    style={{ width: "200px", height: "auto" }}
                  />
                </li>
              </Link>
              <div className="social-icons mt-3 d-block d-md-none">
                <div>Follow On Us</div>
                <a href="https://facebook.com" className="me-3">
                  <i
                    className="bi bi-facebook text-white"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </a>
                <a href="https://twitter.com" className="me-3">
                  <i
                    className="bi bi-twitter text-white"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </a>
                <a href="https://linkedin.com" className="me-3">
                  <i
                    className="bi bi-linkedin text-white"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </a>
                <a href="https://instagram.com" className="me-3">
                  <i
                    className="bi bi-instagram text-white"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </a>
              </div>
            </ul>
          </Col>
        </Row>
        <Row className="text-center pt-4 border-top border-light">
          <Col>
            <p className="mb-0">
              &copy; 2024 EMARSALE SHIPPING. All Rights Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
