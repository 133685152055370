import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import bannerimg4 from "../Assets/images/BannerImage/Gallery.jpg";

// Import local images from the src folder
import image1 from '../Assets/images/Gallery/01 Shipp.jpg';
import image2 from '../Assets/images/Gallery/02 Shipp.jpg';
import image3 from '../Assets/images/Gallery/03 Shipp.jpg';
import image4 from '../Assets/images/Gallery/04 Shipp.jpg';

const images = [image1, image2, image3 ,image4];

const Gallery = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const openLightbox = (image) => {
    setCurrentImage(image);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
    setCurrentImage('');
  };

  return (
    <div>
    {/* Banner Section */}
    <div style={{ position: "relative",  }}>
      <img
        src={bannerimg4}
        alt="Contact Us Banner"
        className="w-100 h-100"
        style={{ maxHeight: "400px", objectFit: "fill" ,minHeight:150 }}
      />
      <h1
        className="display-4 text-center text-md-start"
        style={{
          position: "absolute",
          bottom: "0px",
          left: "20px",
          fontWeight: "bold",
          // fontSize: "clamp(1.5rem, 5vw, 2.5rem)", 
          fontSize: "2rem",
          color: "white",
          textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)", 
        }}
      >
        OUR GALLERY
      </h1>
    </div>
  
    {/* Gallery Grid */}
    <div style={{ marginTop: "20px" }}>
      <Container>
        <Row>
          {images.map((src, index) => (
            <Col lg={3} md={4} sm={6} xs={12} key={index} className="mb-4">
              <Card
                className="image-card shadow-sm"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => openLightbox(src)}
                style={{ cursor: "pointer" }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    height: "250px",
                    width: "100%",
                    borderRadius: "8px",
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={src}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      transition: "transform 0.5s ease",
                      transform: hoveredIndex === index ? "scale(1.1)" : "scale(1)",
                    }}
                  />
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
  
      {/* Lightbox Modal */}
      <Modal show={isLightboxOpen} onHide={closeLightbox} centered size="lg">
        <Modal.Body className="p-0">
          <img
            src={currentImage}
            alt="Lightbox"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
        <Button
          variant="light"
          className="position-absolute top-0 end-0 m-2"
          onClick={closeLightbox}
          aria-label="Close Lightbox"
        >
          ×
        </Button>
      </Modal>
    </div>
  </div>
  
  );
};

export default Gallery;
