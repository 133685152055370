import React from 'react';
import bannerimg4 from "../Assets/images/Subbanner/03 Clients.jpg"

const Clients = () => {
  const clients = [
    { name: 'ALAHARAM TAQA FZCO', logo: 'https://static.vecteezy.com/system/resources/thumbnails/008/214/517/small_2x/abstract-geometric-logo-or-infinity-line-logo-for-your-company-free-vector.jpg', id: 1 },
    { name: 'ALHUTHIELY GENERAL TRADING', logo: 'https://marketplace.canva.com/EAE0rNNM2Fg/1/0/1600w/canva-letter-c-trade-marketing-logo-design-template-r9VFYrbB35Y.jpg', id: 2 },
    { name: 'WORLDLINK-YEMEN', logo: 'https://marketplace.canva.com/EAE0rNNM2Fg/1/0/1600w/canva-letter-c-trade-marketing-logo-design-template-r9VFYrbB35Y.jpg', id: 3 },
    { name: 'UNITED MOTORS & HEAVY EQUIPMENT CO, LLC', logo: 'https://marketplace.canva.com/EAE0rNNM2Fg/1/0/1600w/canva-letter-c-trade-marketing-logo-design-template-r9VFYrbB35Y.jpg', id: 4 },
    { name: 'CARE', logo: 'https://marketplace.canva.com/EAE0rNNM2Fg/1/0/1600w/canva-letter-c-trade-marketing-logo-design-template-r9VFYrbB35Y.jpg', id: 5 },
    { name: 'MAM INTERNATIONAL CORP.', logo: 'https://marketplace.canva.com/EAE0rNNM2Fg/1/0/1600w/canva-letter-c-trade-marketing-logo-design-template-r9VFYrbB35Y.jpg', id: 6 },
    { name: 'BIN HADI GENERAL TRADING EST', logo: 'https://marketplace.canva.com/EAE0rNNM2Fg/1/0/1600w/canva-letter-c-trade-marketing-logo-design-template-r9VFYrbB35Y.jpg', id: 7 },
    { name: 'SPEED LINE LOGISTICS', logo: 'https://marketplace.canva.com/EAE0rNNM2Fg/1/0/1600w/canva-letter-c-trade-marketing-logo-design-template-r9VFYrbB35Y.jpg', id: 8 },
    
  ];

  return (
    <div>
         <div  >
        {/* Image */}
        <div style={{ position: "relative" }}>
        <img
          src={bannerimg4}
          alt="Contact Us Banner"
          className="w-100 h-100"
          style={{ maxHeight: "400px", objectFit: "fill" ,minHeight:150 }}
        />
        <h1
          className="display-4"
          style={{
            position: "absolute",
            bottom: "0px",
            left: "20px",
            fontWeight: "bold",
            fontSize: "2rem",
            color: "white",
          }}
        >
           OUR CLIENTS
        </h1>
      </div>
      </div>
      <div className="container mt-5">
     
      <div className="row">
        {clients.map(client => (
          <div key={client.id} className="col-lg-3 col-md-4 col-sm-6 mb-4 d-flex justify-content-center">
            <div 
              className="card text-center border-primary d-flex flex-column align-items-center rounded" 
              style={{ width: '15rem', height: 'auto', flex: 1 }}
            >
              <img 
                src={client.logo} 
                alt={client.name} 
                className="card-img-top mt-3 rounded-circle"  // Make the image round
                style={{ width: '70%', height: 'auto' }}
              />
              <div className="card-body d-flex flex-column justify-content-end">
                <p className="card-text font-weight-bold" style={{ color: '#023b6d' }}>{client.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Clients;
