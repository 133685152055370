import React from "react";
import Logo from "../../Assets/images/Final Logo.png";
const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#ffffff",
        textAlign: "center",
      }}
    >
        {/* #C2DFFF */}
      
      <style>
        {`
          @keyframes pulse {
            0% {
              opacity: 0.5;
              transform: scale(0.95);
            }
            50% {
              opacity: 1;
              transform: scale(1);
            }
            100% {
              opacity: 0.5;
              transform: scale(0.95);
            }
          }
        `}
      </style>
      <h1
        style={{
       
          animation: "pulse 2s infinite",
       
          
        }}
      >
        <img src={Logo} alt="Company Logo" 
        style={{
            width: "200px",
        }}/>
        
      </h1>
    </div>
  );
};

export default Loader;
